import { postIOSMessage } from './iosBridge'
import { postAndroidMessage } from './androidBridge'

type Message = {
  name: string
  payload: any
}

export const unifiedPostMessage = ({ name, payload }: Message) => {
  const message = JSON.stringify({ name, payload })

  window.postMessage(message)

  postIOSMessage(message)
  postAndroidMessage(message)
}
