import { onePlayHandshakeMessage } from './lib'
import { Node } from './Node'

export class Service extends Node {
  constructor() {
    super()
    const channel = new MessageChannel()
    // @ts-ignore
    this.addPort(channel.port1)
    window.parent.postMessage(onePlayHandshakeMessage, '*', [channel.port2])
  }
}
