import './GameWrapper.css';
import Preloader from "./preloader/Preloader";
import Loader from "./loader/Loader";
import {useParams} from "react-router";

function GameWrapper() {

    var {gameName, gameProvider} = useParams()

    return (
        <div id="game-wrapper">
            <Preloader/>
            <Loader
                gameName={gameName}
                gameProvider={gameProvider}
            />
        </div>
    );
}

export default GameWrapper;