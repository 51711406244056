type Webkit = {
  messageHandlers: {
    message: { postMessage: (data: string) => void }
  }
}

const { webkit } = window as { webkit?: Webkit }

export const postIOSMessage = (message: string): void => {
  if (webkit) {
    webkit.messageHandlers.message.postMessage(message)
  }
}
