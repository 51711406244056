type AndroidBridge = {
  nativePostMessage: (message: string) => void
}

const { androidBridge } = window as {
  androidBridge?: AndroidBridge
}

export const postAndroidMessage = (message: string): void => {
  if (androidBridge) {
    androidBridge.nativePostMessage(message)
  }
}
