import './ProgressBar.css'
import React from "react";

function ProgressBar() {
    window.UnityProgressRef = React.createRef<HTMLDivElement>();
    window.UnityProgressTextRef = React.createRef<HTMLDivElement>();

    return (
        <div id="unity-progress-wrapper">
            <div id="unity-progress-bar-bg"></div>
            <div id="unity-progress-bar" ref={window.UnityProgressRef}></div>
            <div id="unity-progress-text" ref={window.UnityProgressTextRef}>10mb/15mb</div>
        </div>
    )
}

export default ProgressBar;