import React, {RefObject, useMemo, useRef} from "react";
import * as Sentry from '@sentry/browser';
import {Service} from "../communicator";
import './Loader.css';
import * as PlinkoL18n from "./l18n/anubis_plinko.json";
import * as BombucksL18n from "./l18n/bombucks.json";
import * as LuckyLootL18n from "./l18n/lucky_loot.json";
import * as RoyalMinesL18n from "./l18n/royal_mines.json";
import * as GeneralL18n from "./l18n/unity_general.json";

const translationsFallbacks: any = {
    "anubis_plinko": PlinkoL18n,
    "bombucks": BombucksL18n,
    "lucky_loot": LuckyLootL18n,
    "royal_mines": RoyalMinesL18n,
    "unity_general": GeneralL18n,
}

declare global {
    interface Window {
        UnityCanvas: UnityCanvas;
    }

    interface Window {
        createUnityInstance: any;
    }

    interface Window {
        UnityCanvasRef: RefObject<HTMLCanvasElement>;
    }

    interface Window {
        UnityProgressRef: RefObject<HTMLDivElement>;
    }

    interface Window {
        UnityProgressTextRef: RefObject<HTMLDivElement>;
    }

    interface Window {
        unityInstance: any;
    }

    interface Window {
        LoaderHelp: LoaderHelp;
    }

    interface Window {
        UnityResizeCanvas: any;
    }

    interface Window {
        UnityCommunicatorService: any;
    }

    interface Window {
        UnityTranslation: string;
    }

    interface Window {
        UnityAnalyticsURL: string;
    }

    interface Window {
        UnityAnalyticsKey: string;
    }

    interface Window {
        UnityBackendURL: string;
    }

    interface Window {
        UnityTranslations: string;
    }
}

interface UnityCanvasProps {
    gameName: string | undefined
}

interface UnityCanvasState {
    className: string
}

class UnityCanvas extends React.Component<UnityCanvasProps, UnityCanvasState> {
    constructor(props: UnityCanvasProps) {
        super(props);
        this.state = {
            className: this.CalculateClass(props.gameName)
        }
    }

    private GetSearchParameter(name: string) {
        let sp = new URLSearchParams(window.location.search);
        return sp.get(name) || "desktop";
    }

    private CalculateClass(gameName: string | undefined) {
        // mobile
        if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent) || this.GetSearchParameter('forceResolution') === 'mobile') {
            return "full";
        } else if (gameName === "minefield") {
            return "full";
        } else {
            if (window.innerHeight * 1920 / 1080 < window.innerWidth) {
                return "desktop-height";
            } else {
                return "desktop-width";
            }
        }
    }

    public componentDidMount() {
        window.addEventListener(
            'resize',
            () => {
                this.setState(
                    {
                        className: this.CalculateClass(this.props.gameName)
                    }
                );
            },
            false
        );
    }

    public render() {
        window.UnityCanvasRef = React.createRef<HTMLCanvasElement>();

        let className = this.state.className;

        return (
            <canvas id="unity-canvas" ref={window.UnityCanvasRef} className={className}></canvas>
        )
    }
}

class LoaderHelp {
    public AttachLoaderScript(id: string, provider: string, game: string) {
        let loaderScript = document.querySelector("#loader-script")

        if (loaderScript == null) {
            const script = document.createElement('script');
            script.src = `/builds/${game}/build/Build/build.loader.js`;
            script.id = "loader-script";
            script.type = "application/javascript";
            script.async = false;
            script.addEventListener("load", () => this.OnLoadScript(provider, game));
            document.body.appendChild(script);
        }
    }

    public OnLoadScript(provider: string, game: string) {
        window.UnityCommunicatorService = new Service();
        window.UnityTranslation = "";

        window.UnityResizeCanvas = function () {
            console.log("try resize");
        };

        if (window.UnityCanvasRef && window.UnityCanvasRef.current)
            this.StartLoad(provider, game, window.UnityCanvasRef.current).finally();
    }

    public Initialize(provider: string, game: string) {
        this.AttachLoaderScript('loader-script', provider, game);
    }

    private GetSearchParameter(name: string) {
        let sp = new URLSearchParams(window.location.search);
        return sp.get(name) || "en";
    }

    private interval: any;
    private fakeProgress = 0;
    private trueProgress = 0;
    private currentProgress = 0;
    private step = 1;
    private started = false;
    private trueLoaded = false;

    private IntervalFunction() {
        this.currentProgress += this.step;
        this.fakeProgress = Math.round(Math.atan(this.currentProgress) / (Math.PI / 2) * 100 * 1000) / 1000;
        this.UpdateProgressBar();

        if (this.fakeProgress >= 100) {
            clearInterval(this.interval);
        } else if (this.fakeProgress >= 70) {
            this.step = 0.1
        }
    }

    private FakeLoad() {
        this.interval = setInterval(() => this.IntervalFunction(), 100);
    }

    private UpdateProgressBar() {
        if (this.trueLoaded) {
            clearInterval(this.interval);
            if (window.UnityProgressTextRef.current != null)
                window.UnityProgressTextRef.current.innerText = `Starting...`;
            return;
        }

        var fp = Math.round((this.trueProgress + this.fakeProgress) / 2);

        if (window.UnityProgressRef.current != null)
            window.UnityProgressRef.current.style.width = `${fp * 1.5}px`;

        if (window.UnityProgressTextRef.current != null)
            window.UnityProgressTextRef.current.innerText = `Download... ${fp}%`;
    }

    private OnProgress(progress: number) {
        if (!this.started) {
            this.started = true;
            this.FakeLoad()
        }

        if (progress >= 1) {
            this.trueLoaded = true;
        } else {
            this.trueProgress = progress * 100;
            this.UpdateProgressBar();
        }
    }

    private GetLangCode() {
        var lang = this.GetSearchParameter("language");

        var fallback: string[] = [
            'bn', 'hi', 'ja', 'zh', 'ko'
        ]

        var supported: string[] = [
            "ru", "az", "bd", "de", "en", "es", "fr", "id", "hi", "id", "it", "ja", "kk", "ky", "md", "pl", "pt", "tg", "tr", "uk", "uz", "sw", "zh"
        ]

        if (!supported.includes(lang))
            lang = "en";

        if (fallback.includes(lang))
            lang = "en";

        return lang;
    }

    _translationPrepared: boolean = false;

    async FetchTranslation(game: string, lang: string, isStage: boolean, isDev: boolean) {
        console.warn("[UnityApp] FetchTranslation: " + game);

        let fallbackUrl = `/l18n/${game}.json`;
        let url = fallbackUrl;

        if ( isStage )
            url = `https://lang-server.stage.gamedev-atech.cc/fetch/${game}/langs`;

        if ( isDev )
            url = `https://lang-server.dev.gamedev-atech.cc/fetch/${game}/langs`;

        let response: Response | null = null;
        let data: any = {}

        try {
            response = await fetch(url);
        }
        catch (e) {

        }

        if ( response === null || response.status !== 200 )
            try {
                response = await fetch(fallbackUrl);
            }
            catch (e) {

            }

        if ( response === null || response.status !== 200 )
            data = translationsFallbacks[game];
        else
            data = await response.json();

        if (lang in data) {
            return data[lang][game];
        } else {
            return "{}"
        }
    }

    private _extendObj(obj1: any, obj2: any) {
        for (let key in obj2) {
            if (obj2.hasOwnProperty(key)) {
                obj1[key] = obj2[key];
            }
        }

        return obj1;
    }

    async PrepareTranslations(chapters: string[], lang: string, isStage: boolean, isDev: boolean) {
        const translations = [];
        const merged = {}

        for (let i = 0; i < chapters.length; i++) {
            console.log("fetch chapter: " + chapters[i]);
            const translation = await this.FetchTranslation(chapters[i], lang, isStage, isDev);
            translations.push(translation);
            console.log("fetched chapter: " + chapters[i]);
            console.log(translation);
            this._extendObj(merged, translation);
            console.log("merged chapter: " + chapters[i]);
        }

        window.UnityTranslation = JSON.stringify({"translation": merged});
        this._translationPrepared = true;
        console.log(merged)
        console.log(translations)
    }

    public async StartLoad(provider: string, game: string, canvasElement: HTMLCanvasElement) {
        const isDev = window.location.hostname === "localhost" || window.location.hostname.includes(".dev.");
        const isStage = window.location.hostname.includes(".stage.");
        const isProd = !isDev && !isStage;

        let sentryEnv = "";
        let sentryDsn = "";

        switch ( `${game},${isProd},${isStage},${isDev}` ) {
            case 'moreless,true,false,false':
                sentryEnv = 'lucky loot';
                sentryDsn = 'https://91faf52513fe5c660821b12e8f50ca0e@sentry.gamedev-atech.cc/21';
                break
            case 'moreless,false,true,false':
                sentryEnv = 'lucky loot';
                sentryDsn = 'https://43f59db5147a056d0ee33fb36c7c9cae@sentry.gamedev-atech.cc/20'
                break
            case 'moreless,false,false,true':
                sentryEnv = 'lucky loot';
                sentryEnv = 'https://eb1378adb239f3f79cee6fdaab6ee683@sentry.gamedev-atech.cc/19'
                break

            case 'plinko,true,false,false':
                sentryEnv = 'plinko';
                sentryDsn = 'https://03aea48677b91cc5e4cb4a64ffb949f8@sentry.gamedev-atech.cc/21';
                break
            case 'plinko,false,true,false':
                sentryEnv = 'plinko';
                sentryDsn = 'https://a64ed66d04b3a40f12c9330375a611cb@sentry.gamedev-atech.cc/20';
                break
            case 'plinko,false,false,true':
                sentryEnv = 'plinko';
                sentryDsn = 'https://f51002d8616db2abd21cb231ff1e8138@sentry.gamedev-atech.cc/19';
                break

            case 'minefield,true,false,false':
                sentryEnv = 'bombucks';
                sentryDsn = 'https://59e1f180c414b0c36077171e74ad445b@sentry.gamedev-atech.cc/21';
                break
            case 'minefield,false,true,false':
                sentryEnv = 'bombucks';
                sentryDsn = 'https://e5b3ab1bf46cd380622e19ec7d2ea5f6@sentry.gamedev-atech.cc/20';
                break
            case 'minefield,false,false,true':
                sentryEnv = 'bombucks';
                sentryDsn = 'https://b0281914f2c1a2c9a470d9a82f0f9805@sentry.gamedev-atech.cc/19';
                break

            case 'royalmines,true,false,false':
                sentryEnv = 'royal mines';
                sentryDsn = 'https://8c3b0d75e3bb7f42e6196214017512dd@sentry.gamedev-atech.cc/21';
                break
            case 'royalmines,false,true,false':
                sentryEnv = 'royal mines';
                sentryDsn = 'https://76e49cd4a0ffa35f6c6ed813cdc957a4@sentry.gamedev-atech.cc/20';
                break
            case 'royalmines,false,false,true':
                sentryEnv = 'royal mines';
                sentryDsn = 'https://699eec05ed5818bc25b49b3ec60c05cc@sentry.gamedev-atech.cc/19';
                break
        }

        Sentry.init({
            dsn: sentryDsn,
            integrations: [new Sentry.BrowserTracing()],
            tracesSampleRate: 1.0,
            environment: sentryEnv
        });

        switch (provider) {
            case 'lucky-star':
                window.UnityAnalyticsURL = "https://analytics-sink-ls.gamedev-atech.cc/upload_events";
                break
            default:
                window.UnityAnalyticsURL = "https://analytics-sink.gamedev-atech.cc/upload_events";

                if ( isStage )
                    window.UnityAnalyticsURL = "https://analytics-sink.stage.gamedev-atech.cc/upload_events"

                if ( isDev )
                    window.UnityAnalyticsURL = "https://analytics-sink.dev.gamedev-atech.cc/upload_events"

                break
        }

        const langCode = this.GetLangCode();
        let chapters: string[] = [];

        let cases = provider + "," + game;

        switch (cases) {
            case '1win,moreless':
            case 'up-x,moreless':
                window.UnityAnalyticsKey = "F1j6aiB40c";
                break
            case 'lucky-star,moreless':
                window.UnityAnalyticsKey = "TMPZuHyXqm";
                break

            case '1win,plinko':
            case 'up-x,plinko':
                window.UnityAnalyticsKey = "Miz05eQc0C";
                break
            case 'lucky-star,plinko':
                window.UnityAnalyticsKey = "TllBxGXSDd";
                break

            case '1win,minefield':
            case 'up-x,minefield':
                window.UnityAnalyticsKey = "G2cdRjSABj";
                break
            case 'lucky-star,minefield':
                window.UnityAnalyticsKey = "JnhdfkMWaO";
                break

            case '1win,royalmines':
            case 'up-x,royalmines':
                window.UnityAnalyticsKey = "IMr8kmDCSX";
                break
            case 'lucky-star,royalmines':
                window.UnityAnalyticsKey = "Xq5kC09R2y";
                break
        }

        switch (game) {
            case 'moreless':
                window.UnityBackendURL = window.location.hostname === "localhost" ? "https://more-less.dev.gamedev-atech.cc" : `https://${window.location.hostname}`;
                chapters = ["lucky_loot"]
                break
            case 'plinko':
                window.UnityBackendURL = window.location.hostname === "localhost" ? "https://plinko.dev.gamedev-atech.cc" : `https://${window.location.hostname}`;
                chapters = ["anubis_plinko", "unity_general"]
                break
            case 'minefield':
                window.UnityBackendURL = window.location.hostname === "localhost" ? "https://minefield.dev.gamedev-atech.cc" : `https://${window.location.hostname}`;
                chapters = ["bombucks", "unity_general"]
                break
            case 'royalmines':
                window.UnityBackendURL = window.location.hostname === "localhost" ? "https://royal-mines.dev.gamedev-atech.cc" : `https://${window.location.hostname}`;
                chapters = ["royal_mines", "unity_general"]
                break
            case 'minesweeper':
                // gameImportedConfig = minesweeperConfig;
                // window.UnityLangTag = "v5.2.12"
                // window.UnityAnalyticsKey = "ESO6jHntZQ";
                // window.UnityBackendURL = window.location.hostname === "localhost" ? "https://mine-sweeper.dev.gamedev.1win.cloud" : `https://${window.location.hostname}`;
                break
        }

        this.PrepareTranslations(
            chapters,
            langCode,
            isStage,
            isDev
        ).finally();

        const buildUrl = `/builds/${game}/build/Build/`
        const configUrl = `/builds/${game}/build/index.json`
        let response = await fetch(configUrl);
        let gameImportedConfig = await response.json();

        let config = {
            dataUrl: `${buildUrl}/${gameImportedConfig.data}`,
            frameworkUrl: `${buildUrl}/${gameImportedConfig.framework}`,
            codeUrl: `${buildUrl}/${gameImportedConfig.code}`,
            streamingAssetsUrl: "StreamingAssets",
            companyName: "NDA",
            productName: `${buildUrl}/${gameImportedConfig.product}`,
            productVersion: `${buildUrl}/${gameImportedConfig.version}`,
            // matchWebGLToCanvasSize: false, // Uncomment this to separately control WebGL canvas render size and DOM element size.
            devicePixelRatio: window.devicePixelRatio // Uncomment this to override low DPI rendering on high DPI displays.
        };

        window.createUnityInstance(
            canvasElement,
            config,
            (p: number) => this.OnProgress(p)
        ).then(
            (unityInstance: any) => this.OnLoad(unityInstance, canvasElement)
        );
    }

    public OnLoad(unityInstance: any, canvasElement: HTMLCanvasElement) {
        window.unityInstance = unityInstance;
        window.UnityResizeCanvas();
    }
}

class Loader extends React.Component<{ gameName: string | undefined, gameProvider: string | undefined }, any> {
    public componentDidMount() {
        if (!window.LoaderHelp && window.UnityCanvasRef != null && window.UnityCanvasRef.current != null) {
            window.LoaderHelp = new LoaderHelp();
            window.LoaderHelp.Initialize(this.props.gameProvider || "", this.props.gameName || "");
        }
    }

    public render() {
        return (
            <UnityCanvas gameName={this.props.gameName}/>
        );
    }
}

export default Loader;
