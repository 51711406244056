import React from 'react';
import GameWrapper from './GameWrapper';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

function App() {
  const router = createBrowserRouter(
[
      {
        path: "/:gameName/:gameProvider/",
        element: <GameWrapper/>,
      },
    ]
  );

  return (
    <RouterProvider router={router}></RouterProvider>
  );
}

export default App;
