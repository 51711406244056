export default class EventEmitter {
  private listeners: Record<string, EventListener[]>

  constructor() {
    this.listeners = {}
  }

  emit(name: string, message: any) {
    const listeners = this.listeners[name] || []

    Object.values(listeners).forEach(listener => {
      listener(message)
    })
  }

  on(name: string, listener: any) {
    if (!this.listeners[name]) {
      this.listeners[name] = []
    }

    this.listeners[name].push(listener)

    return () => {
      this.listeners[name] = this.listeners[name].filter(cb => cb !== listener)
    }
  }

  once(name: string, listener: any) {
    const unsubscribe = this.on(name, (msg: any) => {
      unsubscribe()
      listener(msg)
    })

    return unsubscribe
  }
}
