import EventEmitter from './EventEmitter';
import {unifiedPostMessage} from "./bridge";

interface MessageEvent {
  data: string;
}

export interface Port {
  onmessage: (event: MessageEvent) => void;
  postMessage: (message: string) => void;
}

export class Node extends EventEmitter {
  private ports: Port[];

  constructor() {
    super();
    this.ports = [];
  }

  addPort(port: Port) {
    this.ports.push(port);
    port.onmessage = this.onMessage.bind(this);
  }

  private onMessage(event: MessageEvent) {
    const message = JSON.parse(event.data);
    super.emit(message.name, message.payload);
    unifiedPostMessage({name: message.name, payload: message.payload});
  }

  emit(name: string, payload: any) {
    this.ports.forEach(port =>
        port.postMessage(JSON.stringify({ name, payload }))
    );
    super.emit(name, payload);
    unifiedPostMessage({name, payload});
  }
}
